import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../index.scss';
import './guides.scss';
import '../../generic-page.scss';
import { Card, Col, Row } from 'react-bootstrap';
import { Reverse1999Character } from '../../../modules/reverse/common/components/rev-character';
import { Reverse1999Psychube } from '../../../modules/reverse/common/components/rev-psychube';

const RevShouldLucy: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page reverse r99-guide'}
      game="reverse"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/re1999/">Reverse: 1999</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/re1999/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Should you pull Lucy?</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/reverse/categories/category_lucy.webp"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Should you pull Lucy?</h1>
          <h2>
            Not sure if pulling Lucy is the right move? No worries! Check our
            review and guide for her!
          </h2>
          <p>
            Last updated: <strong>14/09/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Should You Pull? (TLDR)" />
        <div className="employees-container-card-mode-rev">
          <Reverse1999Character
            slug="lucy"
            mode="icon"
            showTags
            showIcon
            enablePopover
          />
        </div>
        <p>
          We don’t usually say yes or no outright, but in this case most likely
          yes. Lucy is widely considered the best general damage dealer in the
          game. Currently in Global she is only matched by Windsong, and
          performs at an optimal level regardless of what type of content or
          team (unless you specifically build against her) you slot her into.
        </p>
        <p>
          Note that she is a Limited character and, unlike normal new releases,
          will not be added to the standard pool after 3 patches. In addition,
          her banner has a separate pity system from the normal banners and it
          will run for the entire duration of the patch (even through phase 2,
          alongside Kakania).
        </p>
        <SectionHeader title="Lore" />
        <p>
          Lucy’s core was a piston that was a part of the Watt steam engine
          prototype. Born into an era which saw the birth of science as a
          profession, Lucy relentlessly pursues scientific development and pays
          attention to nothing else. The common expressions of humanity—culture,
          customs, status, even manners—things that matter to most humans, hold
          no value to her unless they can be proven to have some demonstrable
          scientific benefit.
        </p>
        <p>
          Having changed out her steam engine for an electric battery, Lucy’s
          abilities and capacity have been greatly increased, yet it came with a
          price: anxiety. She cannot help herself from frantically searching for
          the nearest socket whenever her batteries get low.
        </p>
        <p>
          Having been convinced that scientific inquiry requires a certain
          degree of “camaraderie,” Lucy has made efforts to improve morale at
          LaPlace by developing a “sense of humor”. Her efforts thus far have
          been less than successful… but science is a process of repeated
          iteration. One day she will discover the formula—her next joke is
          already in development.
        </p>
        <SectionHeader title="Review" />
        <p>
          Lucy is a 6-star Arcanist of the Intelligence Afflatus. Both its
          second 6-star and the second True Limited character, Lucy promises to
          be a force to be reckoned with. Her kit is focused around one thing
          and one thing only: damage, and a lot of it. Let's see what's in
          store.
        </p>

        <p>
          The core of Lucy's kit is [Advancement]. After Lucy or allies gain
          Moxie/Eureka (or under certain conditions), Lucy obtains stacks of
          [Dynamo]. Whenever a total of 20 stacks of [Dynamo] has been gained
          (note, gained, not currently carrying), Lucy can upgrade one of her
          two basic skills or her extra incantation for the rest of the battle,
          improving their modifiers and adding additional effects. In addition,
          her insights give her access to [Leadership], which is a new Force
          Field type effect (an effect that only visually applies to Lucy but
          affects her whole team) that grants them additional Afflatus Advantage
          DMG Bonus.
        </p>
        <p>
          "Power of Speed" is Lucy's first Incantation. It's a simple
          single-target nuke that consumes [Dynamo] to deal additional damage.
          After being upgraded through [Advancement], it becomes a 2-target Mass
          attack that can consume [Dynamo] to deal additional damage. Lucy's
          second Incantation, "Electric Surge", is a 2-target Mass attack that
          consumes [Dynamo] to hit one additional target, with its damage
          increasing the fewer enemies are hit. When upgraded through
          [Advancement], it starts dealing additional Genesis DMG.
        </p>
        <p>
          Lucy actually has a secret 3rd Incantation called "Conservation of
          Energy", which counts as a rank 2 Incantation. Her Insight 3 causes
          her to, after casting her Ultimate or when an enemy dies, cast
          "Conservation of Energy" which is a Mass attack that has additional
          Penetration Rate. After being upgraded through [Advancement], it deals
          additional damage to enemies below 50% HP, bypasses [Dodge] and does
          not trigger Reflect or Riposte effects.
        </p>
        <p>
          Lucy's Ultimate, called "A Step Forward", applies a lot of effects at
          once, so here goes. It:
        </p>
        <ul>
          <li>
            Grants Lucy [Pragmatist], which causes her to have Afflatus
            Advantage against any Afflatus, and the damage bonus is increased.
          </li>
          <li>
            Grants Lucy a stack of [Data Iteration], which is a stacking +ATK
            buff that is also gained by killing enemies (Insight 3).
          </li>
          <li>
            Grants Lucy 2 stacks of [Charged Ammunition], each layer granting 5
            [Dynamo] after attacking.
          </li>
        </ul>
        <p>
          It also upgrades through [Advancement] if all other Incantations have
          been upgraded, in which case it:
        </p>
        <ul>
          <li>
            Grants Lucy 2 stacks of [Overcharged Ammunition] instead of [Charged
            Ammunition], this buff grants 10 [Dynamo] per layer.
          </li>
          <li>Also grants +1 Moxie.</li>
        </ul>
        <h6>Gameplay:</h6>
        <p>How does one play Lucy? </p>
        <p>
          The answer to this is: who cares, because in the time it took you to
          ask that, she already killed everyone and is sweeping the floor clean.
          Lucy is a very simple damage dealer. A majority of the text in her kit
          is either dedicated to telling you what her upgraded skills do or
          extra nonsensical effects that were added just to make her more
          powerful than she already was. Press buttons, enemies take damage,
          gain [Dynamo], press them again, they die. Upgrade skills? Now the
          enemies die faster.
        </p>
        <p>
          She does have some interesting things about her, though. “Conservation
          of Energy” triggering primarily on enemy kills means she'll be more
          suited for either multi-wave stages or Raids with additional mobs
          (Gorgon, Isle Master, Gold). Lucy also has some interesting synergies
          with Arcanists that gain Moxie or Eureka quickly. In addition, once
          upgraded, “Conservation of Energy” straight up bypasses [Dodge] and
          stops triggering [Counter] effects, which further opens up her usage
          even in pesky fights where [Dodge] is present. On the other hand, it
          also counts as a rank 2 Incantation, meaning that it helps characters
          who feed off of that such as Mercuria with her [Cosmic Energy].
        </p>
        <p>
          Now, we have characters that can output a lot of multi-target damage,
          so what sets Lucy apart? Not much besides her sky-high numbers,
          however, she has access to [Pragmatist], which is the first known
          instance of a character being able to completely bypass the Afflatus
          chart and force her own Afflatus Advantage. This means that no matter
          what content you slot her into, she will function, do a lot of damage,
          and overall just steamroll the content with minimal effort. In terms
          of generalist Damage Dealers, Windsong is the only one capable of
          rivaling her at the time of writing this.
        </p>
        <h6>Pros & Cons</h6>
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    One of the best multi-target Carries in the game, but also
                    doesn't slack on single-target output.
                  </li>
                  <li>
                    Extremely versatile in team building and content
                    applicability due to forcing Afflatus Advantage and a fairly
                    gimmick-free kit.
                  </li>
                  <li>
                    Technically self-sufficient in her needs; can self-trigger
                    [Advancement], self buff with [Data Iteration] and
                    [Pragmatist], ridiculously high Critical Technique, etc.
                  </li>
                  <li>Extremely easy to play.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>
                    Putting anything here would be either disingenuous or
                    cherry-picking weaknesses that realistically only minimally
                    affect her.
                  </li>
                  <li>True Limited (FOMO) character.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Portrays" />
        <p>Lucy works fine at P0.</p>
        <p>
          Lucy’s Portraits are all fairly viable, though the ones to look out
          for would either be the ones increasing the damage of “Conservation of
          Energy” (Portrait 1) or those that improve [Data Iteration] (Portrait
          3 & 5), as ATK buffs are hard to come by and from her Portraits Lucy
          can stack it to a ridiculously high percentage.
        </p>
        <SectionHeader title="Great Synergies" />
        <p>
          Lucy has no strict “gimmick” and plays well with almost every Support
          in the game that doesn’t explicitly revolve around Mental DMG or the
          likes. However, she does like teammates that can either provide
          Moxie/Eureka for [Advancement] or Incantation Might buffs for
          [Conservation of Energy].
        </p>
        <p>Notable synergies include:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="37"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="vila"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="6"
              showLabel
              mode="icon"
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Character
              slug="getian"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>Future notable synergies:</p>
        <div className="employees-container-card-mode-rev">
          <Card className="avatar-card">
            <Reverse1999Character
              slug="mercuria"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <SectionHeader title="Psychubes" />
        <p>Here are the best Psychubes you can use on Lucy:</p>
        <div className={`employees-container psychube`}>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="steps-forward"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="blasphemer-of-night"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
          <Card className="avatar-card">
            <Reverse1999Psychube
              slug="knock-on-the-door"
              mode="icon"
              showLabel
              showTags
              showIcon
              enablePopover
            />
          </Card>
        </div>
        <p>
          Steps Forward (signature), Blasphemer of Night (if proccable), Knock
          On The Door (short battles)
        </p>
        <SectionHeader title="Resonance" />
        <h6>Level 7</h6>
        <StaticImage
          src="../../../images/reverse/generic/lucy_r_1.webp"
          alt="Guides"
        />
        <h6>Level 9</h6>
        <StaticImage
          src="../../../images/reverse/generic/lucy_r_2.webp"
          alt="Guides"
        />
        <h6>Level 10</h6>
        <StaticImage
          src="../../../images/reverse/generic/lucy_r_3.webp"
          alt="Guides"
        />
        <br />
        <StaticImage
          src="../../../images/reverse/generic/lucy_r_4.webp"
          alt="Guides"
        />
        <SectionHeader title="Teams" />
        <h5>Standard Teams</h5>
        <div className="team-box">
          <h5 className="star">
            Reality DEF Is Not A Real Stat
            <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="isolde"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Getian</strong>
              </li>
              <li>
                The best option in terms of fighting enemies with high Reality
                DEF. Isolde and Getian’s stackable Reality DEF shred greatly
                improves Lucy’s potential damage even against high DEF enemies
                and their constant attacks help with increasing the chances of
                triggering “Conservation of Energy” through mob kills. Vila
                synergizes extremely well with Lucy also by providing
                Moxie/Eureka and also enhancing “Conservation of Energy” damage
                by adding to its damage modifier.
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Imagine Playing The Game<span className="tag">[CN]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="mercuria"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="vila"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Kakania</strong>
              </li>
              <li>
                Essentially a perfectly synergistic team. Lucy is one of the few
                characters who can utilize a Portrait 0 Mercuria due to
                “Conservation of Energy” counting as an Incantation, which helps
                stack [Cosmic Energy] (she also provides Incantation Might with
                it at 16+ stacks). Vila, of course, helps by providing
                Moxie/Eureka for [Advancement] and extra bonuses to the
                “Conservation of Energy” modifier while Kakania solidifies the
                defensive core of the team while adding [Solace], an ATK buff,
                on top of Lucy’s [Data Iteration].
              </li>
            </ul>
          </div>
        </div>
        <div className="team-box">
          <h5 className="star">
            Lucy Budget <span className="tag">[GLB]</span>
          </h5>
          <div className="team">
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="lucy"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="dps">Damage Dealer</h6>
            </div>
            <div className="role mid">
              <div className="characters small">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="bkornblume"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="support">Support</h6>
            </div>
            <div className="role">
              <div className="characters">
                <Card className="avatar-card">
                  <Reverse1999Character
                    slug="yenisei"
                    mode="icon"
                    showTags
                    showIcon
                    enablePopover
                  />
                </Card>
              </div>
              <h6 className="sustain">Sustain</h6>
            </div>
          </div>
          <div className="info">
            <h6>TEAM EXPLANATION</h6>
            <ul>
              <li>
                4th slot options: <strong>Lorelei</strong>
              </li>
              <li>
                A Lucy team composed entirely of 5-stars, despite the
                fundamental “difference” in quality, this team holds up.
                Bkornblume provides consistent Reality DEF shred and DMG Taken
                increases with good DMG output to boot while Lorelei assists
                with additional DMG Dealt and Crit DMG buffs. Lorelei also
                provides defensive (de)buffing in the form of [Weakness] and DMG
                Taken reduction while Yenisei closes it out with extremely high
                healing output, powerful shields and access to [Immunity].
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default RevShouldLucy;

export const Head: React.FC = () => (
  <Seo
    title="Should you pull Lucy? | Reverse: 1999 | Prydwen Institute"
    description="Not sure if pulling Lucy is the right move? No worries! Check our review and guide for her!"
    game="reverse"
  />
);
